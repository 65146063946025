
/**
 * @name: 客户管理-会员等级
 * @author: itmobai
 * @date: 2023-05-31 10:44
 * @description：客户管理-会员等级
 * @update: 2023-05-31 10:44
 */
import {Component, Vue} from "vue-property-decorator";
import {ICrudOption} from "@/types/m-ui-crud";
import {
  userGradeSetupCreateApi,
  userGradeSetupModifyApi,
  userGradeSetupQueryApi,
  userGradeSetupRemoveApi, userGradeSetupStatusUpdateApi
} from "@/apis/customer/level";
import {ILevel} from "@/apis/customer/level/types";
import config from "@/config";
import { checkPositiveInteger } from "@/constants/validators"

@Component({})
export default class LevelPage extends Vue {
  config = config
  // 表格加载状态
  tableLoading: boolean = false;
  // 表格总数
  tableTotal: number = 0;
  // 表格数据
  tableData: ILevel[] = []
  // 表单
  modelForm: Partial<ILevel> = {}
  // 查询参数
  queryParam: any = {
    page: 1,
    limit: 10
  }
  // crudOption
  option: ICrudOption = {
    searchBox: false,
    labelWidth: '120px',
    addTitle: '新增会员等级',
    editTitle: '编辑会员等级',
    column: [
      {
        "label": "ID",
        "prop": "id",
        "align": "left",
        "width": "180",
        "addHide": true,
        "editHide": true,
      },
      {
        "label": "等级名称",
        "prop": "gradeName",
        "align": "center",
        "hide": true,
        "span": 12,
        "maxlength": 20,
        "rules": [
          {required: true, message: "请输入等级名称", trigger: 'blur'}
        ]
      },
      {
        "label": "积分要求",
        "prop": "integral",
        "align": "center",
        "hide": true,
        "span": 12,
        "maxlength": 10,
        "rules": [
          {required: true, message: "请输入积分要求", trigger: 'blur'},
          {validator: checkPositiveInteger, trigger: 'blur'}
        ],
      },
      {
        "label": "等级图标",
        "prop": "gradeIcon",
        "align": "center",
        "type": "image",
        "imgPrefix": config.downloadUrl,
        "addSlot": true,
        "editSlot": true
      },
      {
        "label": "等级背景图",
        "prop": "gradeImg",
        "align": "center",
        "type": "image",
        "imgPrefix": config.downloadUrl,
        "addSlot": true,
        "editSlot": true
      },
      {
        "label": "等级名称",
        "prop": "gradeName",
        "align": "center",
        "addHide": true,
        "editHide": true,
      },
      {
        "label": "等级",
        "prop": "gradeLevel",
        "align": "center",
        "addHide": true,
        "editHide": true,
      },
      {
        "label": "积分要求",
        "prop": "integral",
        "align": "center",
        "addHide": true,
        "editHide": true,
        "slot": true
      },
      {
        "label": "是否显示",
        "prop": "status",
        "align": "center",
        "slot": true,
        "addHide": true,
        "editHide": true,
      }
    ]
  }

  handleDelete (row: ILevel, index: number) {
    userGradeSetupRemoveApi(row.id).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    })
  }

  handleSwitch (id: string, status: number) {
    this.tableLoading = true
    userGradeSetupStatusUpdateApi({id, status}).then(e => {
      if (e) {
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      this.tableLoading = false
    })
  }

  rowSave (form: ILevel, done: Function, loading: Function) {
    userGradeSetupCreateApi(form).then(e => {
      if (e) {
        done()
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  rowEdit (form: ILevel, done: Function, loading: Function) {
    userGradeSetupModifyApi(form).then(e => {
      if (e) {
        done()
        this.$message.success("操作成功!")
        this.getList()
      }
    }).finally(() => {
      loading()
    })
  }

  getList () {
    this.tableLoading = true
    const obj: any = {
      pageParam: {
        page: this.queryParam.page,
        limit: this.queryParam.limit
      }
    }
    userGradeSetupQueryApi(obj).then(e => {
      this.tableLoading = false
      this.tableTotal = e.total
      this.tableData = e.list.map(item => {
        return {
          ...item,
          integral: item.integral ? Number(item.integral) : 0
        }
      });
    })
  }

  created () {
    this.getList()
  }
}
